const isColored = true;

const skillsset = [
    {
        id: "react",
        name: "React",
        iconPath: "/react-100-2",
        extention: ".png",
        proficiency: 9.4,
    },
    {
        id: "performance",
        name: "Performance Optimizations",
        iconPath: "/performance-100-2",
        extention: ".png",
        proficiency: 8,
    },
    {
        id: "vue",
        name: "Vue",
        iconPath: "/vue-js-100",
        extention: ".png",
        proficiency: 9,
    },
    {
        id: "js",
        name: "Javascript",
        iconPath: "/javascript-100",
        extention: ".png",
        proficiency: 9.5,
    },
    {
        id: "html",
        name: "HTML5",
        iconPath: "/html-5-100-3",
        extention: ".png",
        proficiency: 9,
    }, {
        id: "css",
        name: "CSS",
        iconPath: "/css3-100-3",
        extention: ".png",
        proficiency: 8,
    }, {
        id: "sass",
        name: "Sass",
        iconPath: "/sass-100",
        extention: ".png",
        proficiency: 8,
    },
    {
        id: "java",
        name: "Java",
        iconPath: "/java-100-3",
        extention: ".png",
        proficiency: 4.5,
    },
    {
        id: "webpack",
        name: "Webpack",
        iconPath: "/webpack-100",
        extention: ".png",
        proficiency: 7.5,
    },
    {
        id: "jquery",
        name: "Jquery",
        iconPath: "/jquery-100",
        extention: ".png",
        proficiency: 8,
    },
    {
        id: "redux",
        name: "Redux",
        iconPath: "/redux-100",
        extention: ".png",
        proficiency: 9.1,
    },
    {
        id: "ts",
        name: "Typescript",
        iconPath: "/typescript-100",
        extention: ".png",
        proficiency: 5.5,
    },
    {
        id: "node",
        name: "Node js",
        iconPath: "/nodejs-100-2",
        extention: ".png",
        proficiency: 5,
    },
    {
        id: "salesforce",
        name: "Salesforce Commerce Cloud and Marketing Cloud",
        iconPath: "/salesforce-100",
        extention: ".png",
        proficiency: 5,
    },
    {
        id: "git",
        name: "Git",
        iconPath: "/git-100",
        extention: ".png",
        proficiency: 8.5,
    },
]


export const iconPathPrefix = `/icons/${(isColored) ? "colored" : "default"}`;

export default skillsset;