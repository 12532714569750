import React, { Component } from 'react';


class IconRenderer extends Component {

    state = {
        icon: null,
    }

    componentDidMount() {
        if (!this.props.isIconComponent)
            this.loadIcon(this.props.icon);
    }

    loadIcon = (path) => {
        import(`../../assets${path}`).then(icon => {
            this.setState({
                icon: icon.default
            });
        });
    };

    render() {

        if (this.props.isIconComponent) {
            const TagName = this.props.icon;
            return <TagName />
        }
        else {
            return (
                <React.Fragment>
                    {this.state.icon && <img src={this.state.icon}
                        alt={this.props.alt}
                        width={(this.props.isMobile) ? 70 : 100} />}
                </React.Fragment>
            );
        }

    }
}
export default IconRenderer;