import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import App from './App';

/*
const store = createStore(
    reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
*/

ReactDOM.render(<App />, document.getElementById('root'));
 