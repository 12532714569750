import React from 'react';

const Section = (props) => {

    let classes = ["section"];

    if(props.mounted){
        classes.push('mounted');
    }

    if(props.last){
        classes.push('last');
    }

    if(props.base){
        classes.push('base');
    }

    if(props.noPaddingXMobile){
        classes.push('no-padding-x-mobile');
    }
    return (
        <section className={classes.join(" ")} id={props.id}>
            {props.heading ? <h2 className="section__heading">{props.heading}</h2> : ""}
            <div className="section__content">
                {props.children}
            </div>
        </section>
    );
};

export default Section;