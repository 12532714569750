import React, { Component } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import emailjs from 'emailjs-com'
import config from '../config/emailConfig'
import ButtonLoader from './ButtonLoader'
import colorConfig from '../config/colorConfig';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: colorConfig.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: colorConfig.primary,
      },
    },
  }
})(TextField);

class AboutMe extends Component {
  constructor(props) {
    super(props)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.dispatchEmail = this.dispatchEmail.bind(this)
    this.resetEmailScreen = this.resetEmailScreen.bind(this)
    this.state = {
      params: {
        message: ''
      },
      sentScreenShow: false,
      loading: false
    }
  }

  onChangeHandler(e) {
    const newState = {
      params: {
        ...this.state.params,
        [e.target.name]: e.target.value
      }
    }
    this.setState(newState)
  }

  resetEmailScreen() {
    this.setState({
      params: {
        message: ''
      },
      sentScreenShow: false,
      loading: false
    })
  }

  dispatchEmail(e) {
    const context = this
    if (!this.state.params.message || this.state.sentScreenShow) {
      return
    }
    context.setState({ loading: true })

    emailjs.send(config.SERVICE_ID, config.TEMPLATE_ID, this.state.params, config.USER_ID)
      .then(function (response) {
        context.setState({ sentScreenShow: true, loading: false })
        console.log('SUCCESS! : Email Triggered', response.status, response.text)
      }, function (error) {
        console.log('FAILED! : Email Trigger failed', error)
        context.setState({ loading: false })
      })
  }

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <h2 className="skills-heading">Get in touch.</h2>
        </Grid>
        {!this.state.sentScreenShow
          ? (<React.Fragment>
            <Grid item xs={12}>
              <CustomTextField
                variant="outlined"
                id="message"
                name="message"
                multiline
                margin="dense"
                placeholder="I am always up for a chat, project collaboration, or a song recommendation."
                rowsMax={6}
                rows={6}
                onChange={this.onChangeHandler}
                value={this.state.params.message}
                fullWidth
              />
            </Grid>
          </React.Fragment>)
          : (<React.Fragment>
            <Grid item xs={12}>
              <p className="email-sent-msg">
                An email has been sent to me. I will get back to you shortly.
              </p>
            </Grid>
            <Grid item xs={12}>
              <p className="email-sent-msg">
                Missed something?
                <span
                  className='send-another'
                  onClick={this.resetEmailScreen}>
                  Send Another
                </span>
              </p>
            </Grid>
          </React.Fragment>)}
        <Grid item xs={12}>
          <ButtonLoader
            sentScreenShow={this.state.sentScreenShow}
            isLoading={this.state.loading}
            onClickHandler={this.dispatchEmail}
          />
        </Grid>

      </Grid>
    )
  }
}
export default AboutMe
