import React from 'react';
import { Link } from '@material-ui/core';
import { MailOutline, Instagram, LinkedIn, GitHub } from '@material-ui/icons';

const Footer = (props) => {

    return (

        <ul className="footer-wrap">
            <li>
                <Link href="mailto:prateek260996@gmail.com" title="Mail" target="_blank">
                    <MailOutline style={{ color: '#fff', fontSize: 40 }} />
                </Link>
            </li>
            <li>
                <Link href="https://www.instagram.com/prateekgarg10/" title="Instagram" target="_blank">
                    <Instagram style={{ color: '#fff', fontSize: 40 }} />
                </Link>
            </li>
            <li>
                <Link href="https://www.linkedin.com/in/prateekgarg10/" title="LinkedIn" target="_blank">
                    <LinkedIn style={{ color: '#fff', fontSize: 40 }} />
                </Link>
            </li>
            {/* <li>
                <Link href="https://github.com/prateekinfi/" title="GitHub" target="_blank">
                    <GitHub style={{ color: '#fff', fontSize: 40 }} />
                </Link>
            </li> */}
        </ul>
    );
}

export default Footer;