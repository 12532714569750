import React, { Component } from "react";

class ExpMeter extends Component {
  state = {
    workexp: {
      years: { value: 0, label: "years" },
      months: { value: 0, label: "months" },
      days: { value: 0, label: "days" },
      hours: { value: 0, label: "hours" },
      minutes: { value: 0, label: "minutes" },
      seconds: { value: 0, label: "seconds" },
    },
    loading: true,
  };

  updateClock = () => {
    //TODO pass startdate from props
    const startDateTime = new Date(2018, 6, 9, 0, 0, 0, 0);
    const startStamp = startDateTime.getTime();

    const newDate = new Date();
    const newStamp = newDate.getTime();
    let diff = Math.round((newStamp - startStamp) / 1000);

    const newState = {
      ...this.state,
      workexp: {
        ...this.state.workexp,
        years: {
          ...this.state.workexp.years,
        },
        months: {
          ...this.state.workexp.months,
        },
        days: {
          ...this.state.workexp.days,
        },
        hours: {
          ...this.state.workexp.hours,
        },
        minutes: {
          ...this.state.workexp.minutes,
        },
        seconds: {
          ...this.state.workexp.seconds,
        },
      },
      loading: false,
    };
    newState.workexp.years.value = Math.floor(diff / (365 * 24 * 60 * 60));
    diff = diff - newState.workexp.years.value * 365 * 24 * 60 * 60;

    newState.workexp.months.value = Math.floor(
      diff / (4.3479 * 7 * 24 * 60 * 60)
    );
    diff = diff - newState.workexp.months.value * 4.3479 * 7 * 24 * 60 * 60;

    newState.workexp.days.value = Math.floor(diff / (24 * 60 * 60));
    diff = diff - newState.workexp.days.value * 24 * 60 * 60;

    newState.workexp.hours.value = Math.floor(diff / (60 * 60));
    diff = diff - newState.workexp.hours.value * 60 * 60;

    newState.workexp.minutes.value = Math.floor(diff / 60);
    diff = diff - newState.workexp.minutes.value * 60;

    newState.workexp.seconds.value = Math.floor(diff);

    this.setState(newState);
  };
  componentDidMount() {
    this.updateClock();
    this.timer = setInterval(this.updateClock, 1000);
  }

  render() {
    let experience = getExperienceString(this.state.workexp);
    return (
      <span className="total-exp">{!this.state.loading ? experience : ""}</span>
    );
  }
}
export default ExpMeter;

const getExperienceString = (workexp) => {
  let str = "I have been in this industry for ";
  const expArr = Object.keys(workexp);
  try {
    str = expArr
      .filter((timeParam) => workexp[timeParam]?.value > 0)
      .reduce((accumulator, timeParam, currentIndex, arr) => {
        const { value, label } = workexp[timeParam];
        const timeLabel =
          value === 1 ? label.slice(0, label.length - 1) : label;
        const prefix = accumulator + value + " " + timeLabel;

        if (currentIndex === arr.length - 2) {
          return prefix + " and ";
        }

        if (currentIndex === arr.length - 1) {
          return prefix + ".";
        }

        return prefix + ", ";
      }, str);
  } catch (e) {
    return "";
  }
  return str;
};
