import React from 'react'

import IconRenderer from '../global/hoc/IconRenderer'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'

import timelineStages from '../config/expConfig'
import colorConfig from '../config/colorConfig'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main
  }
}))

const CustomColorOneTypography = withStyles({
  root: {
    color: colorConfig.secondary,
    paddingTop: '12px',
    fontWeight: '600',
    fontFamily: `'Montserrat', sans-serif`,
  }
})(Typography);

const CustomColorTwoTypography = withStyles({
  root: {
    color: colorConfig.primary,
    fontWeight: '600',
    fontFamily: `'Montserrat', sans-serif`,
  }
})(Typography);

const CustomColorPaper = withStyles({
  root: {
    background: colorConfig.secondary,
    borderRadius: '10px'
  }
})(Paper);

const CustomTimeline = withStyles({
  root: {
    padding: '6px 0px'
  }
})(Timeline);

export default function ExpTimeline () {
  const classes = useStyles()

  return (
        <React.Fragment>
            {/* <h2 className="skills-heading">Course of my life.</h2> */}
            <CustomTimeline align="alternate">
                {timelineStages.map((stage, index) => {
                  return (
                        <TimelineItem key={stage.id}>
                            <TimelineOppositeContent>
                                <CustomColorOneTypography variant="body2">
                                    {stage.time}
                                </CustomColorOneTypography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot color={colorConfig.primary}>
                                    <IconRenderer isIconComponent={true} icon={stage.icon} />
                                </TimelineDot>
                                {index !== timelineStages.length - 1
                                  ? (
                                    <TimelineConnector />
                                    )
                                  : null}
                            </TimelineSeparator>
                            <TimelineContent>
                                <CustomColorPaper elevation={3} className={classes.paper}>
                                    <CustomColorTwoTypography
                                        variant="h6"
                                        component="h1"
                                        align="center"
                                    >
                                        {stage.title}
                                    </CustomColorTwoTypography>
                                    {stage.description
                                      ? (
                                        <CustomColorTwoTypography align="center">
                                            {stage.description}
                                        </CustomColorTwoTypography>
                                        )
                                      : null}
                                </CustomColorPaper>
                            </TimelineContent>
                        </TimelineItem>
                  )
                })}
            </CustomTimeline>
        </React.Fragment>
  )
}
