import React, { Component } from 'react';
import { Grid, Typography, Tooltip, Zoom } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import skills, { iconPathPrefix } from '../config/skillsConfig';
import IconRenderer from '../global/hoc/IconRenderer';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import colorConfig from "../config/colorConfig";

const primarySkills = skills.filter(item => (item.proficiency >= 7)).sort((i1, i2) => { return i2.proficiency - i1.proficiency }),
    secondarySkills = skills.filter(item => (item.proficiency < 7)).sort((i1, i2) => { return i2.proficiency - i1.proficiency });

const useStylesBootstrap = makeStyles((theme) => ({
    // arrow: {
    //     color: theme.palette.common.black,
    // },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const CustomColorOneTypography = withStyles({
    root: {
      color: colorConfig.primary,
      paddingTop: '12px',
      fontWeight: '600',
      fontSize: '15px',
      fontFamily: `'Montserrat', sans-serif`,
    }
  })(Typography);

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip classes={classes} {...props} />;
}

class Skillset extends Component {
    state = {
        loading: true
    }

    render() {
        return (
            <div className="skills">
                <h2 className="skills-heading">My Arsenal.</h2>
                {/* <Grid container className="skills-table">
                    <Grid item xs={12} sm={6} className="skill-col">
                        Primary Skills
                    </Grid>
                    <Grid item xs={12} sm={6} className="skill-col">
                        Secondary Skills
                    </Grid>
                </Grid> */}
                <Grid container>
                    <Grid item xs={12} className="skills-title"><CustomColorOneTypography variant="button">Primary</CustomColorOneTypography></Grid>
                    {
                        primarySkills.map((skill) => {
                            return (
                                <BootstrapTooltip TransitionComponent={Zoom} title={skill.name} enterTouchDelay={50}>
                                    <Grid item xs={4} sm={2} key={skill.id} className="skill">
                                        <IconRenderer
                                            icon={`${iconPathPrefix}${skill.iconPath}${skill.extention}`}
                                            alt={skill.name}
                                            isMobile={!isWidthUp('sm', this.props.width)} />
                                    </Grid>
                                </BootstrapTooltip>
                            );
                        })
                    }
                </Grid>
                <Grid item xs={12} className="skills-title"><CustomColorOneTypography variant="button">Secondary</CustomColorOneTypography></Grid>

                <Grid container>
                    {
                        secondarySkills.map((skill) => {
                            return (
                                <BootstrapTooltip TransitionComponent={Zoom} title={skill.name} enterTouchDelay={50}>
                                    <Grid item xs={4} sm={2} key={skill.id} className="skill">
                                        <IconRenderer
                                            icon={`${iconPathPrefix}${skill.iconPath}${skill.extention}`}
                                            alt={skill.name}
                                            isMobile={!isWidthUp('sm', this.props.width)} />
                                    </Grid>
                                </BootstrapTooltip>
                            );
                        })
                    }
                </Grid>
            </div>
        );
    }
}
export default withWidth()(Skillset);