import React, { Component } from 'react';
import './App.css';

import Header from './global/Header';
import IntroSection from './components/Intro';
import Section from './global/Section';
import AboutMe from './components/AboutMe';
import ExperienceTimeline from './components/ExpTimeline';
import Skillset from './components/Skillset';
import ContactMe from './components/ContactMe';
import Footer from './components/Footer';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";


class App extends Component {
  render() {
    return (
      <Router>
        <header>
          <Header />
        </header>
        <main>
          <Switch>
            <Route path="/" exact>
              <IntroSection />

              <Section base id="about-me">
                <AboutMe />
              </Section>

              <Section mounted id="skills">
                <Skillset />
              </Section>

              <Section base noPaddingXMobile id="work-exp">
                <ExperienceTimeline />
              </Section>

              <Section mounted last id="contact-me">
                <ContactMe />
              </Section>

            </Route>

            <Route path="*">
              <Redirect to="/" />
            </Route>

          </Switch>
        </main>
        <footer>
          <Footer />
        </footer>
      </Router>
    );
  }
}

export default App;
