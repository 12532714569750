import React, { useEffect, useState } from 'react';

const ScrollDownIndicator = () => {

    const [userHasScrolled, setUserHasScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.addEventListener('scroll', handleScroll);
        }
    }, []);

    const handleScroll = () => {
        setUserHasScrolled(true);
    };

    return (<>
        {(!userHasScrolled) ?
            <div class="scrollContainer">
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
                <span class="text">Scroll</span>
            </div> : null}
    </>
    );
};

export default ScrollDownIndicator;