import React from 'react'
// import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
// import { green } from '@material-ui/core/colors'
import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import SendIcon from '@material-ui/icons/SendRounded'

import colorConfig from '../config/colorConfig'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonSuccess: {
    backgroundColor: colorConfig.primary,
    '&:hover': {
      backgroundColor: colorConfig.primaryHover
    }
  },
  fabProgress: {
    color: colorConfig.primary,
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: colorConfig.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  buttonClassname: {
    background: colorConfig.primary,
    '&:hover': {
      backgroundColor: colorConfig.primaryHover
    }
  }
}))

export default function ButtonLoader (props) {
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const { sentScreenShow, isLoading, onClickHandler } = props

  React.useEffect(() => {
    if (isLoading) {
      setLoading(true)
    } else {
      setLoading(false)
    }

    if (sentScreenShow) {
      setSuccess(true)
    } else {
      setSuccess(false)
    }
  }, [sentScreenShow, isLoading])

  // const handleButtonClick = () => {
  //   if (!loading) {
  //     setSuccess(false);
  //     setLoading(true);
  //     timer.current = window.setTimeout(() => {
  //       setSuccess(true);
  //       setLoading(false);
  //     }, 2000);
  //   }
  // };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          className={classes.buttonClassname}
          onClick={onClickHandler}
        >
          {success ? <CheckIcon /> : <SendIcon className={classes.send}/>}
        </Fab>
        {loading && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
    </div>
  )
}
