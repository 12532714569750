import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import ExpMeter from './ExpMeter';

class AboutMe extends Component {
    state = {
        loading: true
    }


    render() {
        return (
            <Grid container>

                <Grid item xs={12}>
                    <div className="about-me__content">
                        <h2 className="sub-heading">About Me.</h2>
                        <p className="paragraph-1">
                            I am passionate about solving problems and writing code.
                            I want to build a diverse set of skills and explore different horizons of technology.
                        </p>
                        <p className="paragraph-2">
                            I am currently working at Zoom where I work towards developing features that are critical in the growth of business. 
                        </p>
                        <p className="paragraph-3">
                            <ExpMeter />
                        </p>
                    </div>
                </Grid>
                {/* <Grid item xs={12} md={4} className="about-me__image">
                    <img src={displayPicture} alt="Prateek Garg" />
                </Grid> */}
            </Grid>
        );
    }
}
export default AboutMe;