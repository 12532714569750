import { Link } from '@material-ui/core';
import React from 'react';

const header = () => {
    const scrollTo = (id) => {
        document.getElementById(id).scrollIntoView();
    } ;

    return (
        <ul className="headerWrap">
            <li>
                <Link onClick={() => scrollTo('about-me')}>About me</Link>
            </li>
            <li>
                <Link onClick={() =>  scrollTo('skills')}>Skills</Link>
            </li>
            <li>
                <Link onClick={() => scrollTo('work-exp')}>Experience</Link>
            </li>
            <li>
                <Link onClick={() => scrollTo('contact-me')}>Get in touch</Link>
            </li>
        </ul>
    );
};

export default header;