import React from 'react';
import logo from '../assets/hi.gif';
import ScrollDownIndicator from './ScrollDownIndicator';

class Intro extends React.Component {
    state = {
        displayLine: "I am Prateek Garg.",
    }

    componentDidMount() {

        let prefix = "I am ",
            suffixes = [
                "Prateek Garg.",
                "an Engineer.",
                "a Web Developer."
            ],
            counter = 0,
            writing = true;

        let displayValue = prefix,
            i = 0;

        setInterval(() => {

            if (writing) {
                displayValue += suffixes[counter % 3][i];
                i++;
            } else {
                displayValue = displayValue.slice(0, -1);
                i--;
            }
            this.setState({
                displayLine: displayValue
            });

            if (i > suffixes[counter % 3].length - 1) {
                writing = false;
                i--;
            } else if (i < 0) {
                writing = true;
                i++;
                counter++;
            }

        }, 100);


    }
    render() {
        return (
            <section className="introWrap">
                <div className="hi-logo">
                    <img src={logo} alt="Hi." height="200" />
                </div>
                <div className="intro">
                    {this.state.displayLine}
                </div>
                <span className="cursor"> | </span>
                <div className="scrollIndicatorWrapper">
                    <ScrollDownIndicator />
                </div>
            </section>
        );
    }
}

export default Intro;