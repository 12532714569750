
import ChildCareIcon from '@material-ui/icons/ChildCare';
import ExposurePlus2Icon from '@material-ui/icons/ExposurePlus2';
// import WorkIcon from '@material-ui/icons/Work';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import SchoolIcon from '@material-ui/icons/School';

const timelineStages = [
    {
        id: "birth",
        time: "September, 1996",
        title: "Born",
        description: "",
        icon: ChildCareIcon
    },
    {
        id: "hsc",
        time: "April, 2014",
        title: "Finished Secondary School",
        description: "",
        icon: ExposurePlus2Icon
    },
    {
        id: "graduation",
        time: "June, 2018",
        title: "Graduated from Thapar University",
        description: "",
        icon: SchoolIcon
    },
    {
        id: "job1",
        time: "July, 2018",
        title: "Started working at Publicis Sapient",
        description: "",
        icon: WorkOutlineIcon
    },
    {
        id: "job2",
        time: "March, 2021",
        title: "Started working at Paytm",
        description: "",
        icon: WorkOutlineIcon
    },
    {
        id: "job3",
        time: "September, 2022",
        title: "Started working at Zoom",
        description: "",
        icon: WorkOutlineIcon
    },
];

export default timelineStages;